import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateTransportLetter } from "../../services/Index";
import { bankingDetail } from "../../utils/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const TransportLetter = () => {
  const navigate = useNavigate();
  const [dataChanged, setDataChanged] = useState(false);
  const [transportData, setTransportData] = useState({ attachments: [] });
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const buttonDisabledData =
    !formData.payeeName &&
    !formData.payeeAddress &&
    !formData.branchName &&
    !formData.sortCode &&
    !formData.accountNumber &&
    !formData.buildingSocietyRoleNumber
      ? false
      : true;

  const [errors, setErrors] = useState({});

  const location = useLocation();
  const { from } = location?.state;

  useEffect(() => {
    setTransportData(from);
    setFormData(from);
  }, []);

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result.split(",")[1];
        setFiles([
          ...files,
          {
            fileName: file.name,
            documentBody: Base64,
            mimeType: file.type,
          },
        ]);
      };
      reader.onerror = function (error) {};
    }
  };

  const handleUploadFile = (e) => {
    const { files } = e.target;
    const updatedAttachments = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      encodeFileBase64(file);
      updatedAttachments.push({
        fileName: file.name,
        documentBody: file.documentBody,
        mimeType: file.type,
      });
    }

    setTransportData((oldData) => ({
      ...oldData,
      attachments: oldData.attachments
        ? [...oldData.attachments, ...updatedAttachments]
        : updatedAttachments,
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setDataChanged(true);
    const { name, value } = e.target;

    setTransportData((oldData) => ({ ...oldData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidated = await validateTransportRequestSchemma(transportData);

    if (isValidated) {
      if (dataChanged === false) {
        toast.warning("There is nothing to be updated yet.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      try {
        setLoading(true);

        await updateTransportLetter(transportData);
        toast.success("Banking Detail Added successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {}
      setDataChanged(false);
      navigate("/TransportLettersList");
      setLoading(false);
    }
  };

  const validateTransportRequestSchemma = async (data) => {
    // return true;
    const isValid = await bankingDetail.isValid(data);
    if (isValid === true) {
      return true;
    } else {
      try {
        await bankingDetail.validate(data, { abortEarly: false });
        setErrors({});
      } catch (err) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        toast.error("Please fill all the required fields.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      }
      return false;
    }
  };

  return (
    <div className="settings-main">
      {loading && (
        <div className="center">
          <RotatingLines
            strokeColor="#4e3782"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      {!loading && (
        <div className="container">
          <div className="formhead">
            <i className="fa fa-clipboard">&nbsp;</i>
            <span className="text-left">Banking Details</span>
          </div>
          <Form>
            <h2 className="mt-3">Banking Information</h2>
            <div className="row setting-box transport-letterbox">
              <div className="col-md-4 mb-3">
                <label>Payee Name</label>
                <Form.Control
                  type="text"
                  name="payeeName"
                  placeholder="Payee Name"
                  value={transportData?.payeeName}
                  onChange={handleChange}
                  disabled={formData.payeeName}
                />
                {errors.payeeName && !transportData?.payeeName && (
                  <span className="error">{errors.payeeName}</span>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>Payee Address</label>
                <Form.Control
                  type="text"
                  name="payeeAddress"
                  placeholder="Payee Address"
                  value={transportData?.payeeAddress}
                  onChange={handleChange}
                  disabled={formData.payeeAddress}
                />
                {errors.payeeAddress && !transportData?.payeeAddress && (
                  <span className="error">{errors.payeeAddress}</span>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>Branch Name </label>
                <Form.Control
                  type="text"
                  name="branchName"
                  placeholder="Branch Name "
                  value={transportData?.branchName}
                  onChange={handleChange}
                  disabled={formData.branchName}
                />
                {errors.branchName && !transportData?.branchName && (
                  <span className="error">{errors.branchName}</span>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>Sort Code </label>
                <Form.Control
                  type="text"
                  name="sortCode"
                  placeholder="Sort Code "
                  value={transportData?.sortCode}
                  disabled={formData.sortCode}
                  onChange={handleChange}
                />
                {errors.sortCode && !transportData?.sortCode && (
                  <span className="error">{errors.sortCode}</span>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>Account Number</label>
                <Form.Control
                  type="text"
                  name="accountNumber"
                  placeholder="Account Number"
                  value={transportData?.accountNumber}
                  disabled={formData.accountNumber}
                  onChange={handleChange}
                />
                {errors.accountNumber && !transportData?.accountNumber && (
                  <span className="error">{errors.accountNumber}</span>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>Building Society Roll No.</label>
                <Form.Control
                  type="text"
                  name="buildingSocietyRoleNumber"
                  placeholder="Building Society Roll No."
                  value={transportData?.buildingSocietyRoleNumber}
                  disabled={formData.buildingSocietyRoleNumber}
                  onChange={handleChange}
                />
                {errors.buildingSocietyRoleNumber &&
                  !transportData?.buildingSocietyRoleNumber && (
                    <span className="error">
                      {errors.buildingSocietyRoleNumber}
                    </span>
                  )}
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label" for="Data_FormFile">
                  Select file
                </label>
                <p>
                  <input
                    id="Data_FormFile"
                    name="Data.FormFile"
                    type="file"
                    onChange={handleUploadFile}
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png"
                    // multiple
                  />
                </p>

                {/* <div id="helpBlock" class="form-text text-danger">
                    Maximum file size: 50 MB.
                  </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="disabled-btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={buttonDisabledData}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default TransportLetter;
