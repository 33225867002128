import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { updateTransportAgreement } from "../../services/Index";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const TransportAgreementHSB = () => {
  const [termCondition, setTermCondition] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getFormattedDate = (dateofBirth) => {
    const dateString = dateofBirth;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // add leading zero if needed
    const day = ("0" + date.getDate()).slice(-2); // add leading zero if needed
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const handleSubmit = async () => {
    const data = {
      termsandConditionsAccepted: termCondition,
      agreementId: from.agreementId
    };

    await updateTransportAgreement(data);

    toast.success("You have accepted the agreement successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      theme: "light",
    });

    navigate("/TransportAgreementsList");
  };
  return (
    <>
      <div className="container transport-agreement">
        <div className="formhead">
          <i className="fa fa-clipboard">&nbsp;</i>
          <span className="text-left">
            Travel Assistance School Bike Scheme
          </span>
          <h4>Letter of Agreement </h4>
        </div>

        <Card className="p-4">
          <h5>Dear {from.transportRequest.customerNameDetails.title + " "
            + from.transportRequest.customerNameDetails.firstName + " "
            + from.transportRequest.customerNameDetails.lastName
          }</h5>
          <p>
            I am pleased to advise you that under the School Travel Assistance
            policy you have been provided with <b>£{from.contract.bikeVoucherCost} of Halfords
              vouchers</b> in order for you to purchase a bike and any associated
            items to assist and support getting {from.student.firstName +
              " " + from.student.lastName} to and from
            their education setting only.{" "}
          </p>
          <p>
            This letter sets out the terms of the School Bike Scheme which
            Telford & Wrekin Council (‘the Council’) is prepared to make to
            {" " + from.transportRequest.customerNameDetails.firstName +
              " " + from.transportRequest.customerNameDetails.lastName +
              "/" + from.student.firstName +
              " " + from.student.lastName} (‘the Recipient’) and in accepting in
            the Halfords vouchers from the Council you agree to the following
            terms. Please read them carefully and return your signed copy of
            this letter within 10 working days of the date above to
            show your acceptance of the terms and condition of the voucher. Upon
            completion of this agreement the voucher will be sent to you via
            email.{" "}
          </p>
          <ul className="agreement-list-box">
            <li>
              The purpose of this voucher is for the purchase of a bike,
              associated items, and any maintenance product to assist getting
              {from.student.firstName + " " + from.student.lastName} to and from their education setting only.{" "}
            </li>
            <li>
              The Recipient agrees they will be responsible for arranging
              {from.student.firstName + " " + from.student.lastName} travel to school/college and they shall not
              use the voucher for any purpose other than to cover all associated
              costs of a bike, safety equipment, bike lock, and maintenance
              contract.
            </li>
            <li>
              The Council have undertaken an initial route risk assessment and a
              bikeability qualified instructor has undertaken an initial cycling
              assessment with {from.student.firstName + " " + from.student.lastName} and deemed {from.student.firstName + " " + from.student.lastName} and
              the route to school suitable for this scheme.{" "}
            </li>
            <li>
              The Recipient agrees that {from.student.firstName + " " + from.student.lastName} take part in level
              3 bikeability training further details can be found on the
              following website
              https://www.bikeability.org.uk/get-cycling/cycle-training-for-children/.
              The Council will deliver this training over a number of sessions,
              the amount of sessions delivered will be agreed on a case by case
              basis.{" "}
            </li>
            <li>
              The Recipient agrees that they will be responsible for ensuring
              that {from.student.firstName + " " + from.student.lastName} arrives at school/college safely and
              securely.
            </li>
            <li>
              The Recipient agrees that they will be responsible for ensuring
              that {from.student.firstName + " " + from.student.lastName} arrive at school/college on time.
            </li>
            <li>
              The Recipient agrees to insure the bike, either through a separate
              policy, or by adding this to their home contents insurance. If the
              Recipient incurs additional costs associated with purchasing a
              bike insurance policy then the Council will reimburse these costs
              of up to £35 a year via a payment voucher. The Recipient will only
              be reimbursed upon receipt of evidence to show that the insurance
              policy is in place and the cost of such policy. If the Recipient
              defaults on any insurance payment then the Council reserves the
              right to claim back any payments given.{" "}
            </li>
            <li>
              The Recipient will provide the Council with a receipt as evidence
              of the purchase of the bike and any other associated items and
              insurance.{" "}
            </li>
            <li>
              The Recipient where required to do so will allow the Council
              access to the bike, safety equipment, bike lock and any other
              associated items in relation to the vouchers in order to carry out
              any necessary inspections.{" "}
            </li>
            <li>
              It will be the responsibility of the Recipient to maintain the
              bike and ensure it is kept in a reliable, safe and clean
              condition, and they acknowledge that the Council will have no
              liability whatsoever for the suitability, ongoing maintenance or
              repair of the bike.{" "}
            </li>
            <li>
              The Council will not increase the voucher amount of supply any
              additional vouchers in any circumstances.{" "}
            </li>
            <li>
              The Recipient agrees and acknowledges that in the event that the
              bike is lost or stolen, and that the Council will not be liable
              for such replacement.{" "}
            </li>
            <li>
              The Recipient will indemnify the Council against any loss or
              damage arising from any breach of these terms.{" "}
            </li>
            <li>
              The Recipient acknowledges that they are responsible for using the
              equipment safely and in accordance with the Highway Code. You must
              behave sensibly and follow any safety precautions so as not to
              hurt or injure yourself or other.{" "}
            </li>
            <li>
              The Council may share information about the School Bike Scheme or
              the voucher scheme with parties of its choice as well as with
              anyone who make an information access request under the Freedom of
              Information Act 2000 or other relevant information law provisions.
            </li>
          </ul>
          <h5> Bike and equipment specification </h5>
          <ul className="agreement-list-box">
            <p>
              The Halfords vouchers are provided to you for purchasing a bike
              and safety equipment for the purpose of cycling safely to and from
              school. The staff at Halfords have been trained to provide advice
              and guidance in relation to bike sizing and appropriateness, they
              will also be able to provide assistance and advice around the
              purchasing of bike accessories. You should seek there guidance
              when selecting your bike and accessories to ensure they meet the
              requirements for you and your journey. We do however stipulate the
              following criteria is met when selecting your bike;
            </p>

            <li>Bikes must either be, mountain, hybrid or a road bike. </li>
            <li>
              {" "}
              Bikes must not be modified in any way that would result in the
              bike becoming less effective or damaged.{" "}
            </li>
            <li>
              {" "}
              You must not remove the safety accessories that come with the
              bike, these include; front, rear and pedal reflectors and the
              bell.
            </li>

            <p>
              The purchasing of bike accessories is not limited to, but must
              include the following;
            </p>

            <li> A helmet – must meet the EN1078 standard</li>
            <li>
              {" "}
              A set of bike lights a white light at the front and a red light
              for the back of the bike – strongly advise that you select a set
              which is USB chargeable
            </li>
            <li> Reflective bag cover and/or reflective vest</li>
            <li> A D bike lock that with secure your bike</li>
            <li> Water/winter proof gloves</li>
            <li>
              {" "}
              Basic repair kit and storage bag – to include a minimum of tyre
              levers, multi tool, spare inner tube, hand pump
            </li>
          </ul>
          <p>
            The Recipient is required to use the voucher to purchase the
            Halfords Care Plan for the duration of this agreement.{" "}
          </p>
          <h5>Repayment of the Voucher</h5>
          <ul className="agreement-list-box">
            <li>
              The Council may demand repayment of the voucher at the Council’s
              absolute discretion if you fail to comply with the terms of this
              letter.{" "}
            </li>
            <li>
              The Recipient agrees that they will repay in full the voucher
              within 28 days in the event that bike is sold or otherwise
              disposed of during the {from.transportRequest.customerNameDetails.firstName + " "
                + from.transportRequest.customerNameDetails.lastName} period of
              use, whilst they are in education.
            </li>
          </ul>
          <h5> Law</h5>
          <ul className="agreement-list-box">
            <li>These terms are subject to English Law.</li>
          </ul>
          <h5>Agreement start date: </h5>
          <ul className="agreement-list-box">
            <li>{getFormattedDate(from.contract.contractStartDate)}</li>
          </ul>
          <h5> Agreement end date: </h5>
          <ul className="agreement-list-box">
            <li>{getFormattedDate(from.contract.contractEndDate)}</li>
          </ul>

          {from.termsandConditionsAccepted != true ?
            <div class="form-check mt-2 mb-2 p-0 d-flex align-items-center">
              <input
                class="form-check-input"
                type="checkbox"
                onChange={() => setTermCondition(!termCondition)}
                name="AccurateDetails"
              />
              <p>I have read and agree to the terms and conditions.</p>
            </div> : ""}

          {from.termsandConditionsAccepted != true ?
            <div className="col-md-12 d-flex justify-content-end settings-main">
              <button
                className="disabled-btn"
                onClick={handleSubmit}
                disabled={termCondition === true ? false : true}
              >
                Accept
              </button>
            </div> : ""}
        </Card>
      </div>
    </>
  );
};

export default TransportAgreementHSB;
