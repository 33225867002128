import React, { useEffect } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getCustomerDetails, getToken } from "./services/Index";
//component
import Footer from "./components/Footer";
import Index from "./components/Index";
import Home from "./components/TransportApplication/Home";
import MainHeader from "./components/Header";
import RiskAssessment from "./components/RiskAssessment/RiskAssessment";
import RiskAssessmentList from "./components/RiskAssessment/List";
import Form from "./components/TransportApplication/Index";
import Profile from "./components/Profile";
//style
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "./components/Dialog";
import TransportLetterList from "./components/TransportLetter/List";
import TransportAgreementList from "./components/TransportAgreement/List";
import TransportLetter from "./components/TransportLetter/TransportLetter";
import TransportAgreementHSB from "./components/TransportAgreement/TransportAgreementHSB";
import TransportAgreementPTB from "./components/TransportAgreement/TransportAgreementPTB";

function App() {
  let AzureADB2C = JSON.parse(localStorage.getItem("AzureADB2C")) || {};
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      getCustomerDetails(AzureADB2C).then((response) => {
        // response.email = AzureADB2C.email;
        localStorage.setItem("customerDetails", JSON.stringify(response));
      });
    }
  }, [isAuthenticated]);

  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <MainHeader />
        <Routes>
          {/* <Route path="/" element={<Index />}> */}
          <Route path="/" element={<Index />} />
          {isAuthenticated && <Route path="/Applications" element={<Home />} />}
          {isAuthenticated && (
            <Route
              path="/RiskAssessmentsList"
              element={<RiskAssessmentList />}
            />
          )}
          {isAuthenticated && (
            <Route exact path="/RiskAssessment" element={<RiskAssessment />} />
          )}
          {isAuthenticated && <Route path="/Apply" element={<Form />} />}
          {isAuthenticated && <Route path="/Profile" element={<Profile />} />}
          {isAuthenticated && (
            <Route
              path="/TransportLettersList"
              element={<TransportLetterList />}
            />
          )}
          {isAuthenticated && (
            <Route path="/TransportLetter" element={<RiskAssessment />} />
          )}
          {isAuthenticated && (
            <Route
              path="/TransportAgreementsList"
              element={<TransportAgreementList />}
            />
          )}
          {isAuthenticated && (
            <Route path="/TransportAgreement" element={<RiskAssessment />} />
          )}
          {isAuthenticated && (
            <Route
              path="/TransportLetterDetail"
              element={<TransportLetter />}
            />
          )}
          {isAuthenticated && (
            <Route
              path="/TransportAgreementHSB"
              element={<TransportAgreementHSB />}
            />
          )}
          {isAuthenticated && (
            <Route
              path="/TransportAgreementPTB"
              element={<TransportAgreementPTB />}
            />
          )}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
