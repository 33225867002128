import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { updateTransportAgreement } from "../../services/Index";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const TransportAgreementPTB = () => {
  const [termCondition, setTermCondition] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getFormattedDate = (dateofBirth) => {
    const dateString = dateofBirth;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // add leading zero if needed
    const day = ("0" + date.getDate()).slice(-2); // add leading zero if needed
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const handleSubmit = async () => {
    const data = {
      termsandConditionsAccepted: termCondition,
      agreementId: from.agreementId
    };

    await updateTransportAgreement(data);

    toast.success("You have accepted the agreement successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      theme: "light",
    });

    navigate("/TransportAgreementsList");
  };

  return (
    <>
      <div className="container transport-agreement">
        <div className="formhead">
          <i className="fa fa-clipboard">&nbsp;</i>
          <span className="text-left">Personal Transport Budget</span>
          <h4>Letter of Agreement </h4>
        </div>

        <Card className="p-4">
          <h5>Dear {from.transportRequest.customerNameDetails.title + " "
            + from.transportRequest.customerNameDetails.firstName + " "
            + from.transportRequest.customerNameDetails.lastName
          }</h5>
          {/* <h4 className="mt-4 text-center ">This agreement is between; </h4>
          <div className="row mt-4">
            <div className="col-md-6 mb-3">
              <Form.Control className="py-2" type="text" placeholder="" />
            </div>
            <div className="col-md-6 mb-3">
              <Form.Control className="py-2" type="text" placeholder="" />
            </div>
            <div className="col-md-6 mb-3">
              <Form.Control className="py-2" type="text" placeholder="" />
            </div>
            <div className="col-md-6 mb-3">
              <Form.Control className="py-2" type="text" placeholder="" />
            </div>
          </div> */}
          <p>
            {" "}
            This document sets out the agreement between the Council (referred
            to as “we”, “us” or “our”) and the Parent/Guardian/Carer of the
            Child/Children(referred to as “you” or “your”) which will be
            applicable when your child receives a Personal Transport Budget
            (PTB) from us. This document is intended to be legally binding and
            in signing it you agree to fully comply with the terms and
            conditions set out below. The agreement sets out what your and our
            responsibilities are with respect to the payment and receipt of the
            PTB and when and how either you or we may end the agreement.
          </p>
          <h5>Council’s obligations: </h5>
          <ul className="agreement-list-box">
            <span>Under this agreement we will: </span>
            <li>
              Calculate your Child/ren’s budget based on your Child/ren’s
              circumstances and individual needs.
            </li>
            <li>
              Pay you the PTB into your nominated bank account in advance of
              each month, in monthly instalments specified below for the
              duration of this agreement. You must notify us immediately in the
              event that there is a change in your bank details.
            </li>
            <li>
              In the event that you inform us that you are encountering problems
              with your Child/ren’s travel arrangements or your family
              circumstances change, arrange alternative travel assistance if you
              can no longer organise transport yourself. You must provide us
              with at least 30 days’ written notice or less in exceptional
              circumstances, where reasonably practicable, the Council will
              agree to alternative arrangements to be put in place.
            </li>
            <li>
              Review your Child/ren’s needs once a year or sooner if
              circumstances change (e.g. a change of family address; or a change
              of school).
            </li>
            <li>
              Keep monitoring to a minimum, however, we will pick up any
              potential issues relating to attendance and punctuality via the
              school’s attendance records and where issues occur, may
              renegotiate your Child/ren’s PTB appropriately.
            </li>
            <li>
              Where attendance or punctuality are affected by your Child/ren’s
              travel arrangements, talk over any issues with you and actively
              work with you and the school to attempt to resolve any issues.
            </li>
            <li>
              Contact you and discuss any changes that may be considered
              necessary to your Child/ren’s PTB e.g. where it needs to be
              adjusted or stopped (e.g. during pupil illness; if attendance
              drops to an unacceptably low level; change of address during the
              school year).
            </li>
            <li>
              Have the right to suspend or stop paying the PTB in the event that
              you misuse the money, including putting your Child/ren, yourself
              or others at serious risk of neglect or harm.
            </li>
            <li>
              Recover any overpayments of the PTB that occur as a result of a
              change of circumstances that you have not notified us about. In
              the event that you do not repay any sums requested by us as being
              payable, we may pursue the recovery of such sums by taking
              appropriate legal action.{" "}
            </li>
            <li>
              Suspend this agreement where you fail to meet the obligations
              contained in it and have failed to rectify any issues that we have
              identified and requested you to remedy within 14 days of being
              asked to do so.
            </li>
            <li>
              Provide you with a minimum of 30 days’ notice to terminate this
              agreement.{" "}
            </li>
          </ul>
          <h5> Parent/Guardian/Carer’s Obligations:</h5>
          <ul className="agreement-list-box">
            <span>Under this Agreement you will:</span>
            <li>
              {" "}
              Be responsible for arranging your Child/ren’s travel to
              school/college using the PTB to cover all associated costs.
            </li>
            <li>
              Be responsible for ensuring that your Child/ren is/are escorted to
              school/college safely and securely.
            </li>
            <li>
              Be responsible for ensuring that your Child/ren arrive at
              school/college on time.
            </li>
            <li>
              Be responsible for notifying our Transport Team immediately if you
              encounter any difficulties with your travel arrangements for your
              Child/ren or if there is a change in your family circumstances
              e.g. you and your Child/ren move address; your Child/ren leave
              their current school.{" "}
            </li>
            <li>
              Be responsible for notifying our Transport Team of any material
              change in circumstance which may affect your entitlement to PTB.{" "}
            </li>
            <li>
              Provide our Transport Team with a minimum of 30 days’ notice
              (unless there are exceptional circumstances) where you require us
              to organise alternative travel solutions for your Child/ren.
            </li>
            <li>
              Be aware that if your Child/ren’s attendance drops to an
              unacceptable level or they persistently do not arrive at school on
              time, then the PTB is at risk of being reduced, suspended or
              stopped by us.
            </li>
            <li>
              Supply us with your nominated bank account details below if you
              haven’t previously done so and notify us immediately of any
              changes to your nominated bank account.{" "}
            </li>
          </ul>
          <h5> Commencement, Duration and Termination</h5>
          <ul className="agreement-list-box">
            <span>Under this Agreement you will:</span>
            <li> This Agreement shall expire on: </li>

            <li>
              The Council may extend this agreement beyond the initial term, by
              mutual agreement with Parent/ Guardian/Carers/.
            </li>

            <li>
              Either Party may voluntarily terminate this Agreement by giving
              the other Party no less than 30 days notice.{" "}
            </li>
          </ul>
          <h5>Detail of PTB Agreed</h5>
          <div className="agreement-table-box">
            <div className="row">
              <div className="col-5 px-0">
                <div className="agree-box">First payment of;</div>
                <div className="agree-box">
                  Followed by Monthly instalments of;
                </div>
                <div className="agree-box">PTB start date;</div>
              </div>
              <div className="col-7 px-0">
                <div className="agree-box">£ {from.contract.monthlyCost}</div>
                <div className="agree-box">£ {from.contract.monthlyCost}</div>
                <div className="agree-box">{getFormattedDate(from.contract.contractStartDate)}</div>
              </div>
            </div>
          </div>
          <h5>
            I confirm that I understand and agree to comply with the conditions
            contained in this agreement:
          </h5>
          <div className="agreement-table-box">
            <div className="row">
              <div className="col-5 px-0">
                <div className="agree-box">Name Parent/Guardian/Carer</div>
                <div className="agree-box">Address</div>
                <div className="agree-box">Date</div>
              </div>
              <div className="col-7 px-0">
                <div className="agree-box">{from.transportRequest.customerNameDetails.title + " "
                  + from.transportRequest.customerNameDetails.firstName + " "
                  + from.transportRequest.customerNameDetails.lastName
                }</div>
                <div className="agree-box">{from.transportRequest.addressdetail.houseNameNumber + " "
                  + from.transportRequest.addressdetail.streetName + " "
                  + from.transportRequest.addressdetail.area + " "
                  + from.transportRequest.addressdetail.postCode + " "
                  + from.transportRequest.addressdetail.townorCity}</div>
                <div className="agree-box">{getFormattedDate(from.todayDate)}</div>
              </div>
            </div>
          </div>
          {from.termsandConditionsAccepted != true ?
            <div class="form-check mt-4 mb-4 p-0 d-flex align-items-center">
              <input
                class="form-check-input"
                type="checkbox"
                onChange={() => setTermCondition(!termCondition)}
                name="AccurateDetails"
              />
              <p>I have read and agree to the terms and conditions.</p>
            </div> : ""}
          <p className="fw-bold">
            {" "}
            Should you require impartial advice or support in relation to this
            agreement you can contact Information, Advice and Support Service
            (IASS) to obtain confidential and impartial advice and support.{" "}
          </p>
          <p className="fw-bold">IASS contact details: 01952 457176</p>
          {from.termsandConditionsAccepted != true ?
            <div className="col-md-12 d-flex justify-content-end settings-main">
              <button
                className="disabled-btn"
                onClick={handleSubmit}
                disabled={termCondition === true ? false : true}
              >
                Accept
              </button>
            </div> : ""}
        </Card>
      </div>
    </>
  );
};

export default TransportAgreementPTB;
