import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const Dialog = () => {
    const [show, setShow] = useState(true);
    const [checkboxValue, setCheckboxValue] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCheck = () => {
        setCheckboxValue(!checkboxValue);
    };
    return (
        <>
            <Modal centered backdrop="static" show={show} onHide={handleClose} keyboard={false}>
                <Modal.Header className="mb-0">
                    <Modal.Title>Important</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="formwrapper content-wrap policies-box modal-box">
                        <p>
                            Before completing the travel assistance application form please ensure you have
                            completed all the profile details.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-btn2">
                        <Link
                            to="/Profile"

                            onClick={handleClose}
                        >
                            Proceed
                        </Link>
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Dialog;
