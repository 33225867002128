import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

//Components
import Policies from "../Policies";
import Uploader from "./Uploader";

import { emailRegExp, phoneRegExp, transportRequestSchema } from "../../utils/Helper";
import {
  contactTitles,
  CourseHoursTypes,
  educationTypes,
  groundsForApplicationTypes,
  mobilityEquipmentTypes,
  seatTypes,
} from "../../utils/GlobalChoices";
import {
  EducationTypes,
  GroundsForApplication,
  MobilityEquipmentTypes,
  Seat,
} from "../../utils/Constants";
import {
  getCustomerDetails,
  getEmergencyContactDetails,
  getExistingStudent,
  getSchoolList,
} from "../../services/Index";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "../Dialog";

const Form = () => {
  const [data, setData] = useState({
    ContactEmail: "",
    ContactPhone: "",
    EmergencyContact1Phone: "",
    EmergencyContact1Email: "",
    EmergencyContact2Phone: "",
    EmergencyContact2Email: "",
  });
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState("step1");
  const [dialog, setDialog] = useState(false);
  const [dialogTwo, setDialogTwo] = useState(false);
  //list of values
  const [schools, setSchoolList] = useState([]);
  const [existingStudent, setExistingStudent] = useState([]);
  const [emergencyContactDetails, setEmergencyContactDetails] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getCustomerDetails({}).then((res) => {
      setData((oldData) => ({
        ...oldData,
        ContactEmail: res.email,
        ContactTitle: res.title,
        ContactFirstName: res.firstName,
        ContactLastName: res.lastName,
        ContactAddressline1: res.addressline1,
        ContactAddressline2: res.addressline2,
        ContactAddressline3: res.addressline3,
        ContactAddressline4: res.city,
        ContactAddressPostcode: res.postalCode,
        ContactPhone: res.phone,
      }));

      if (!res.email || !res.title || !res.firstName || !res.lastName
        || !res.addressline1 || !res.addressline2 || !res.addressline3
        || !res.postalCode || !res.phone) {
        setDialog(true);
      }
      else {
        setDialogTwo(true);
      }

    });
    getExistingStudent().then((res) => {
      setExistingStudent(res);
    });
  }, []);

  const validateTransportRequestSchemma = async (data) => {
    // return true;
    const isValid = await transportRequestSchema.isValid(data);
    if (isValid === true) {
      return true;
    } else {
      try {
        await transportRequestSchema.validate(data, { abortEarly: false });
        setErrors({});
      } catch (err) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        toast.error("Please fill all the required fields.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
        console.log("These are errors: ", validationErrors);
      }
      return false;
    }
  };

  const handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (
      name == "EducationSchoolType" ||
      name == "ContactTitle" ||
      name == "GroundsForApplication" ||
      name == "MobilityEquipment" ||
      // name == "TransportSeatType" ||
      name == "EducationHoursPerWeek" ||
      name == "InYearOrNextAcademicYear" ||
      name == "StudentSex"
    ) {
      value = parseInt(e.target.value, 10);
    }
    if (name == "EducationSchoolType") {
      const updatedSchoolList = await getSchoolList(value);
      setSchoolList(updatedSchoolList);
    }
    if (name == "StudentId") {
      const updatedmergencyContactDetail = await getEmergencyContactDetails(
        value
      );
      setEmergencyContactDetails(updatedmergencyContactDetail);
      updatedmergencyContactDetail.forEach((ec, i) => {
        if (i == 0) {
          setData((oldData) => ({
            ...oldData,
            EmergencyContact1Name: ec.fullname,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact1Relationship: ec.relationship,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact1Phone: ec.phone,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact1Email: ec.email,
          }));
        }
        if (i == 1) {
          setData((oldData) => ({
            ...oldData,
            EmergencyContact2Name: ec.fullname,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact2Relationship: ec.relationship,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact2Phone: ec.phone,
          }));
          setData((oldData) => ({
            ...oldData,
            EmergencyContact2Email: ec.email,
          }));
        }
      });
    }
    setData((oldData) => ({ ...oldData, [name]: value }));
  };
  const handleClick = async (step) => {
    const isValidated = await validateTransportRequestSchemma(data);
    if (step == "step2" && isValidated) {
      setStep(step);
    }
  };
  return (
    <Container>
      {dialog === true && <Dialog />}
      {step === "step1" && (
        <>
          {dialog === false && dialogTwo === true && (
            <Policies />)}
          <div>
            <div className="formwrapper content-wrap index-form-box">
              <div className="policies-box-heading">
                <h3 className="mt-4">Step 1 - Application Details</h3>
              </div>

              <div className="card mb-3">
                <div className="">
                  <div className="mb-3">
                    <h4>Customer / Emergency Contact Details</h4>
                  </div>
                  <div className="userinfo-section">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactTitle">Title</label>
                          <select
                            className="form-control"
                            id="Data_ContactTitle"
                            name="ContactTitle"
                            disabled
                            value={data.ContactTitle}
                            onChange={handleChange}
                          >
                            <option value="">Select title</option>
                            {contactTitles &&
                              contactTitles.map((title) => (
                                <option value={title.attributeValue}>
                                  {title.attributeName}
                                </option>
                              ))}
                          </select>
                          {errors.ContactTitle && !data.ContactTitle && (
                            <span className="error">{errors.ContactTitle}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactFirstName">
                            First name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            disabled
                            maxLength={99}
                            id="Data_ContactFirstName"
                            name="ContactFirstName"
                            value={data.ContactFirstName}
                            placeholder="Specify first name"
                            onChange={handleChange}

                          />
                          {errors.ContactFirstName &&
                            !data.ContactFirstName && (
                              <span className="error">
                                {errors.ContactFirstName}
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactSurname">Surname</label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactSurname"
                            name="ContactLastName"
                            value={data.ContactLastName}
                            placeholder="Specify surname"
                            onChange={handleChange}
                          />
                          {errors.ContactLastName && !data.ContactLastName && (
                            <span className="error">
                              {errors.ContactLastName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactAddressLine1">
                            House name / number
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactAddressLine1"
                            name="ContactAddressline1"
                            value={data.ContactAddressline1}
                            placeholder="Specify house name/number"
                            onChange={handleChange}
                          />
                          {errors.ContactAddressline1 &&
                            !data.ContactAddressline1 && (
                              <span className="error">
                                {errors.ContactAddressline1}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactAddressLine2">
                            Street name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactAddressLine2"
                            name="ContactAddressline2"
                            value={data.ContactAddressline2}
                            placeholder="Specify street name"
                            onChange={handleChange}
                          />
                          {errors.ContactAddressline2 &&
                            !data.ContactAddressline2 && (
                              <span className="error">
                                {errors.ContactAddressline2}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactAddressLine3">
                            Area
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactAddressLine3"
                            name="ContactAddressline3"
                            value={data.ContactAddressline3}
                            placeholder="Specify Area"
                            onChange={handleChange}
                          />
                          {errors.ContactAddressline3 &&
                            !data.ContactAddressline3 && (
                              <span className="error">
                                {errors.ContactAddressline3}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactAddressLine4">
                            Town / city
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactAddressLine4"
                            name="ContactAddressline4"
                            value={data.ContactAddressline4}
                            placeholder="Specify town/city"
                            onChange={handleChange}
                          />
                          {errors.ContactAddressline4 &&
                            !data.ContactAddressline4 && (
                              <span className="error">
                                {errors.ContactAddressline4}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactAddressPostCode">
                            Post code
                          </label>
                          <input
                            className="form-control text-uppercase"
                            type="text"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactAddressPostCode"
                            name="ContactAddressPostcode"
                            value={data.ContactAddressPostcode}
                            placeholder="Specify post code"
                            onChange={handleChange}
                          />
                          {errors.ContactAddressPostcode &&
                            !data.ContactAddressPostcode && (
                              <span className="error">
                                {errors.ContactAddressPostcode}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactEmail">
                            Email address
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactEmail"
                            disabled
                            // placeholder="Specify email address"
                            onChange={handleChange}
                            value={data.ContactEmail}
                            name="ContactEmail"
                          />
                          {/* {errors.ContactEmail && !data.ContactEmail.match(emailRegExp) && (
                            <span className="error">{errors.ContactEmail}</span>
                          )} */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label htmlFor="Data_ContactPhone">
                            Phone number
                          </label>
                          <input
                            className="form-control"
                            type="tel"
                            disabled
                            autoComplete="off"
                            maxLength={99}
                            id="Data_ContactPhone"
                            name="ContactPhone"
                            value={data.ContactPhone}
                            placeholder="Specify phone number"
                            onChange={handleChange}
                          />
                          {errors.ContactPhone && !data.ContactPhone.match(phoneRegExp) && (
                            <span className="error">{errors.ContactPhone}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="mb-3">
                  <label htmlFor="Data_StudentType">
                    Is this request for a new or existing child / young person?
                    (required)
                  </label>
                  <select
                    className="form-control"
                    id="Data_StudentType"
                    name="StudentType"
                    onChange={handleChange}
                  >
                    <option value="">
                      Please select if this is a new or existing child / young
                      person
                    </option>
                    <option value="Existing">Existing</option>
                    <option value="New">New</option>
                  </select>
                  {errors.StudentType && !data.StudentType && (
                    <span className="error">{errors.StudentType}</span>
                  )}
                </div>

                {data.StudentType && data.StudentType == "Existing" && (
                  <div id="studentsListDiv">
                    <div className="mb-3">
                      <label htmlFor="Data_StudentId">
                        Please select an existing child / young person
                        (required)
                      </label>
                      <select
                        id="studentsList"
                        className="form-control"
                        name="StudentId"
                        onChange={handleChange}
                      >
                        <option value="">
                          Select an existing child / young person
                        </option>
                        {existingStudent &&
                          existingStudent.map((std) => (
                            <option value={std.studentId}>
                              {std.firstName + " " + std.lastName}
                            </option>
                          ))}
                      </select>
                      {errors.StudentId && !data.StudentId && (
                        <span className="error">{errors.StudentId}</span>
                      )}
                    </div>
                  </div>
                )}

                {/* Section: Student details */}
                {data.StudentType && data.StudentType == "New" && (
                  <div id="studentFormDiv">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label htmlFor="Data_StudentFirstName">
                            Child / young person first name (required)
                          </label>
                          <div className="mb-3">
                            <input
                              className="form-control"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentFirstName"
                              name="StudentFirstName"
                              placeholder="Specify child / young person first name"
                              onChange={handleChange}
                              value={data.StudentFirstName}
                            />
                            {errors.StudentFirstName &&
                              !data.StudentFirstName && (
                                <span className="error">
                                  {errors.StudentFirstName}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label htmlFor="Data_StudentSurname">
                            Child / young person surname (required)
                          </label>
                          <div className="mb-3">
                            <input
                              className="form-control"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentSurname"
                              name="StudentLastName"
                              placeholder="Specify child / young person surname"
                              onChange={handleChange}
                            />
                            {errors.StudentLastName &&
                              !data.StudentLastName && (
                                <span className="error">
                                  {errors.StudentLastName}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label htmlFor="Data_StudentSex">
                            Child / young person sex (required)
                          </label>
                          <div className="mb-3">
                            <select
                              className="form-control"
                              id="Data_StudentSex"
                              name="StudentSex"
                              onChange={handleChange}
                            >
                              <option value={-1}>Select value</option>
                              <option value={126560001}>Female</option>
                              <option value={126560000}>Male</option>
                            </select>
                            {errors.StudentSex && !data.StudentSex && (
                              <span className="error">{errors.StudentSex}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label htmlFor="Data_StudentDob">
                            Child / young person date of birth - DD/MM/YYYY
                            (required)
                          </label>
                          <div className="mb-3">
                            <input
                              className="form-control datepicker"
                              type="date"
                              id="Data_StudentDob"
                              name="StudentDateOfBirth"
                              placeholder="DD/MM/YYYY"
                              onChange={handleChange}
                            />
                            {errors.StudentDateOfBirth &&
                              !data.StudentDateOfBirth && (
                                <span className="error">
                                  {errors.StudentDateOfBirth}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label htmlFor="Data_RelationshipToStudent">
                        What is your relationship to the child / young person
                        (required)
                      </label>
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="text"
                          autoComplete="off"
                          maxLength={99}
                          id="Data_RelationshipToStudent"
                          name="ContactRelationship"
                          placeholder="Specify your relationship"
                          onChange={handleChange}
                        />
                        {errors.ContactRelationship &&
                          !data.ContactRelationship && (
                            <span className="error">
                              {errors.ContactRelationship}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label htmlFor="Data_studentDetailsInCare">
                        Is this application for a child / young person in care
                        or leaving care? (required)
                      </label>
                      <select
                        id="Data_studentDetailsInCare"
                        className="form-control"
                        name="StudentDetailsInCare"
                        onChange={handleChange}
                      >
                        <option value="">Select value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                      {errors.StudentDetailsInCare &&
                        !data.StudentDetailsInCare && (
                          <span className="error">
                            {errors.StudentDetailsInCare}
                          </span>
                        )}
                    </div>
                  </div>
                </div>

                {data.StudentDetailsInCare === "true" && (
                  <div id="detailInCare">
                    <div className="mb-3">
                      <div className="alert alert-info" role="alert">
                        Travel assistance will only be considered for a child /
                        young person in care or leaving care in the Telford and
                        Wrekin area.
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="Data_StudentSocialWorker">
                        Please provide the full name of the social worker for
                        the child / young person in care (required)
                      </label>
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="text"
                          autoComplete="off"
                          maxLength={99}
                          id="Data_StudentSocialWorker"
                          name="StudentDetailsSocialWorker"
                          placeholder="Specify social worker full name."
                          onChange={handleChange}
                        />
                        {errors.StudentDetailsSocialWorker &&
                          !data.StudentDetailsSocialWorker && (
                            <span className="error">
                              {errors.StudentDetailsSocialWorker}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="Data_SchoolType">
                      What type of education will the child / young person be
                      in? (required)
                    </label>
                    <select
                      id="schoolType"
                      className="form-control"
                      name="EducationSchoolType"
                      onChange={handleChange}
                    >
                      <option value={-1}>Select type</option>
                      {educationTypes &&
                        educationTypes.map((edType) => (
                          <option value={edType.attributeValue}>
                            {edType.attributeName}
                          </option>
                        ))}
                    </select>
                    {errors.EducationSchoolType &&
                      (!data.EducationSchoolType ||
                        data.EducationSchoolType == -1) && (
                        <span className="error">
                          {errors.EducationSchoolType}
                        </span>
                      )}
                  </div>
                </div>

                {data.EducationSchoolType &&
                  data.EducationSchoolType !== -1 &&
                  data.EducationSchoolType !== EducationTypes.PRIMARY &&
                  data.EducationSchoolType !== EducationTypes.SECONDARY && (
                    <div
                      className="alert alert-warning"
                      role="alert"
                      id="post16ContributeAlert"
                    >
                      <p>
                        Post sixteen applicants are required to contribute towards
                        transport payments in the following circumstances: A funded travel
                        pass to occupy a seat on a taxi or minibus or a taxi uplift Personal
                        Transport Budget (PTB) are only provided in exceptional circumstances.
                        For these modes of travel, a contribution of £600 per academic year
                        from students or their parents is required towards the cost of this travel assistance. The charge will be £400
                        per academic year, if a financially dependent student is from a low income household.
                        Applicants on low income will contribute less but are required to upload documentary
                        evidence as part of this application to prove low income status.
                      </p>
                      <hr />
                      {/* Not included in the database yet */}
                      <div className="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="ConfirmAgreeToPayContribution"
                          onChange={handleChange}
                        />
                        {errors.ConfirmAgreeToPayContribution && (
                          <span className="error">
                            {errors.ConfirmAgreeToPayContribution}
                          </span>
                        )}
                        <label
                          className="form-check-label"
                        >
                          Please check this box to confirm that you understand
                          the above statement and are prepared to contribute
                          towards transport costs (required).
                        </label>
                      </div>
                    </div>
                  )}

                {data.EducationSchoolType &&
                  (data.EducationSchoolType === EducationTypes.PRIMARY ||
                    data.EducationSchoolType === EducationTypes.SECONDARY) && (
                    <div className="mb-3" id="studentLivesAtDifferentAddress">
                      <label htmlFor="Data_StudentLivesAtDifferentAddress">
                        Does the child / young person live at a different
                        address to yourself? (required)
                      </label>
                      <select
                        id="livesAtDifferentAddress"
                        className="form-control"
                        name="StudentDetailsLivesAtDifferentAddress"
                        onChange={handleChange}
                      >
                        <option value="">Select value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                      {errors.StudentDetailsLivesAtDifferentAddress &&
                        !data.StudentDetailsLivesAtDifferentAddress && (
                          <span className="error">
                            {errors.StudentDetailsLivesAtDifferentAddress}
                          </span>
                        )}
                    </div>
                  )}

                {(data.EducationSchoolType === EducationTypes.PRIMARY
                  || data.EducationSchoolType === EducationTypes.SECONDARY)
                  && data.StudentDetailsLivesAtDifferentAddress === "true" && (
                    <div id="detailStudentAddress" className="">
                      <div className="alert alert-info" role="alert">
                        Please specify the child / young person address details
                        below.
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="Data_StudentAddressLine1">
                              House name/number
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentAddressLine1"
                              name="StudentDetailsAddressLine1"
                              placeholder="Specify house name/number"
                              onChange={handleChange}
                            />
                            {errors.StudentDetailsAddressLine1 &&
                              !data.StudentDetailsAddressLine1 && (
                                <span className="error">
                                  {errors.StudentDetailsAddressLine1}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="Data_StudentAddressLine2">
                              Street name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentAddressLine2"
                              name="StudentDetailsAddressLine2"
                              placeholder="Specify street name"
                              onChange={handleChange}
                            />
                            {errors.StudentDetailsAddressLine2 &&
                              !data.StudentDetailsAddressLine2 && (
                                <span className="error">
                                  {errors.StudentDetailsAddressLine2}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="Data_StudentAddressLine3">
                              Area
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              autoComplete="off"
                              maxLength={99}
                              id="Data_StudentAddressLine3"
                              name="StudentDetailsAddressLine3"
                              placeholder="Specify locality"
                              onChange={handleChange}
                            />
                            {errors.StudentDetailsAddressLine3 &&
                              !data.StudentDetailsAddressLine3 && (
                                <span className="error">
                                  {errors.StudentDetailsAddressLine3}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="Data_StudentAddressLine4">
                              Town/city
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentAddressLine4"
                              name="StudentDetailsAddressLine4"
                              placeholder="Specify town/city"
                              onChange={handleChange}
                            />
                            {errors.StudentDetailsAddressLine4 &&
                              !data.StudentDetailsAddressLine4 && (
                                <span className="error">
                                  {errors.StudentDetailsAddressLine4}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label htmlFor="Data_StudentAddressPostCode">
                              Post code
                            </label>
                            <input
                              className="form-control text-uppercase"
                              type="text"
                              maxLength={99}
                              autoComplete="off"
                              id="Data_StudentAddressPostCode"
                              name="studentDetailsAddressPostcode"
                              placeholder="Specify post code"
                              onChange={handleChange}
                            />
                            {errors.StudentDetailsAddressLine4 &&
                              !data.StudentDetailsAddressLine4 && (
                                <span className="error">
                                  {errors.StudentDetailsAddressLine4}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}

                <div className="mb-3" id="schoolCollege">
                  <label htmlFor="Data_schoolId">
                    Name of school/college attending (required)
                  </label>
                  <div className="mb-3">
                    <select
                      className="form-control"
                      id="Data_schoolId"
                      name="SchoolId"
                      onChange={handleChange}
                    >
                      <option value="">Select a school/college</option>
                      {schools &&
                        schools.map((school) => (
                          <option value={school.id}>{school.schoolName}</option>
                        ))}
                    </select>
                    <div className="form-text">
                      Please contact us if the school/college is missing from the above list via{" "}
                      <a
                        href="mailto:travelassistance@telford.gov.uk?subject=Travel assistance - Missing school/college"
                        title="Contact us via email"
                      >
                        travelassistance@telford.gov.uk
                      </a>
                      .
                    </div>
                    {errors.SchoolId && !data.SchoolId && (
                      <span className="error">{errors.SchoolId}</span>
                    )}
                  </div>
                </div>

                {data.EducationSchoolType &&
                  (data.EducationSchoolType === EducationTypes.PRIMARY ||
                    data.EducationSchoolType === EducationTypes.SECONDARY) && (
                    <div className="mb-3" id="schoolStartDate">
                      <label htmlFor="Data_SchoolAdmittanceDate">
                        Date admitted to school - DD/MM/YYYY (required)
                      </label>
                      <div className="mb-3">
                        <input
                          type="date"
                          className="form-control datepicker"
                          id="Data_SchoolAdmittanceDate"
                          name="EducationSchoolAdmittanceDate"
                          placeholder="DD/MM/YYYY"
                          onChange={handleChange}
                        />
                        {errors.EducationSchoolAdmittanceDate &&
                          !data.EducationSchoolAdmittanceDate && (
                            <span className="error">
                              {errors.EducationSchoolAdmittanceDate}
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                {data.EducationSchoolType &&
                  data.EducationSchoolType !== -1 &&
                  data.EducationSchoolType !== EducationTypes.PRIMARY &&
                  data.EducationSchoolType !== EducationTypes.SECONDARY && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="Data_CourseTitle">
                          Title of the course (required)
                        </label>
                        <div className="mb-3">
                          <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            maxLength={99}
                            id="Data_CourseTitle"
                            name="EducationCourseTitle"
                            placeholder="Specify course title"
                            onChange={handleChange}
                          />
                          {errors.EducationCourseTitle &&
                            !data.EducationCourseTitle && (
                              <span className="error">
                                {errors.EducationCourseTitle}
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="Data_Qualification">
                          What qualification will this course lead to? - BTEC,
                          NVQ, A levels etc (required)
                        </label>
                        <div className="mb-3">
                          <input
                            className="form-control"
                            type="text"
                            maxLength={99}
                            autoComplete="off"
                            id="Data_Qualification"
                            name="EducationQualification"
                            placeholder="Specify qualification"
                            onChange={handleChange}
                          />
                          {errors.EducationQualification &&
                            !data.EducationQualification && (
                              <span className="error">
                                {errors.EducationQualification}
                              </span>
                            )}
                        </div>
                      </div>
                    </>
                  )}

                {data.EducationSchoolType &&
                  (data.EducationSchoolType === -1 ||
                    data.EducationSchoolType === EducationTypes.PRIMARY ||
                    data.EducationSchoolType === EducationTypes.SECONDARY) && (
                    <div className="mb-3" id="applicationGrounds">
                      <label htmlFor="Data_GroundsForApplication">
                        On what grounds are you applying? (required)
                      </label>
                      <select
                        className="form-control"
                        id="groundsForApplicationSelect"
                        name="GroundsForApplication"
                        onChange={handleChange}
                      >
                        <option value={-1}>Select value</option>
                        {groundsForApplicationTypes &&
                          groundsForApplicationTypes.map(
                            (groundForApplication) => (
                              <option
                                value={groundForApplication.attributeValue}
                              >
                                {groundForApplication.attributeName}
                              </option>
                            )
                          )}
                      </select>
                      {errors.GroundsForApplication &&
                        !data.GroundsForApplication && (
                          <span className="error">
                            {errors.GroundsForApplication}
                          </span>
                        )}
                    </div>
                  )}

                {data.GroundsForApplication &&
                  data.GroundsForApplication ===
                  GroundsForApplication.MEDICAL_OR_SPECIAL_EDUCATION_NEEDS && (
                    <div
                      className="alert alert-warning"
                      role="alert"
                      id="groundsMedicalAlert"
                    >
                      If you applying on medical grounds you must provide
                      supporting evidence from a medical consultant. Upload
                      supporting documents after submitting this form.
                    </div>
                  )}

                {data.EducationSchoolType &&
                  data.EducationSchoolType !== -1 &&
                  data.EducationSchoolType !== EducationTypes.PRIMARY &&
                  data.EducationSchoolType !== EducationTypes.SECONDARY && (
                    <>
                      <div id="hoursPerWeek" className="mb-3">
                        <label htmlFor="Data_HoursPerWeek">
                          How many hours per week will the child / young person
                          be completing on this course? (required)
                        </label>
                        <select
                          className="form-control"
                          id="Data_HoursPerWeek"
                          name="EducationHoursPerWeek"
                          onChange={handleChange}
                        >
                          <option value={-1}>Select value</option>
                          {CourseHoursTypes &&
                            CourseHoursTypes.map((ch) => (
                              <option value={ch.attributeValue}>
                                {ch.attributeName}
                              </option>
                            ))}
                        </select>
                        {errors.EducationHoursPerWeek &&
                          !data.EducationHoursPerWeek && (
                            <span className="error">
                              {errors.EducationHoursPerWeek}
                            </span>
                          )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="Data_FirstYearOfStudy">
                          Is this the child / young person's first year of
                          studying this course? (required)
                        </label>
                        <select
                          className="form-control"
                          id="Data_FirstYearOfStudy"
                          name="EducationFirstYearOfStudy"
                          onChange={handleChange}
                        >
                          <option value="">Select value</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.EducationFirstYearOfStudy &&
                          !data.EducationFirstYearOfStudy && (
                            <span className="error">
                              {errors.EducationFirstYearOfStudy}
                            </span>
                          )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="Data_NearestSchoolCollege">
                          Is the child / young person studying at their nearest
                          and suitable school/college offering this course
                          (required)
                        </label>
                        <select
                          id="nearestSchoolCollegeSelect"
                          className="form-control"
                          name="EducationNearestSchoolCollege"
                          onChange={handleChange}
                        >
                          <option value="">Select value</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.EducationNearestSchoolCollege &&
                          !data.EducationNearestSchoolCollege && (
                            <span className="error">
                              {errors.EducationNearestSchoolCollege}
                            </span>
                          )}
                      </div>
                    </>
                  )}

                {(data.EducationSchoolType !== EducationTypes.PRIMARY &&
                  data.EducationSchoolType !== EducationTypes.SECONDARY)
                  && data.EducationNearestSchoolCollege === "false" && (
                    <div className="mb-3">
                      <label htmlFor="Data_WhyNotNearestSchool">
                        Please state why (required)
                      </label>
                      <textarea
                        rows={3}
                        maxLength={3999}
                        className="form-control"
                        autoComplete="off"
                        id="Data_WhyNotNearestSchool"
                        name="EducationWhyNotNearestSchool"
                        placeholder="Specify why"
                        onChange={handleChange}
                      ></textarea>
                      {errors.EducationWhyNotNearestSchool &&
                        !data.EducationWhyNotNearestSchool && (
                          <span className="error">
                            {errors.EducationWhyNotNearestSchool}
                          </span>
                        )}
                    </div>
                  )}

                {data.EducationSchoolType &&
                  data.EducationSchoolType !== -1 &&
                  data.EducationSchoolType !== EducationTypes.PRIMARY &&
                  data.EducationSchoolType !== EducationTypes.SECONDARY && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="Data_WhyNotFamilyOrFriends">
                          Why can't transport be provided by the child / young
                          person's family or friends? (required)
                        </label>
                        <textarea
                          rows={3}
                          maxLength={3999}
                          className="form-control"
                          autoComplete="off"
                          id="Data_WhyNotFamilyOrFriends"
                          name="TransportWhyNotFamilyOrFriends"
                          placeholder="Specify why"
                          onChange={handleChange}
                        ></textarea>
                        {errors.TransportWhyNotFamilyOrFriends &&
                          !data.TransportWhyNotFamilyOrFriends && (
                            <span className="error">
                              {errors.TransportWhyNotFamilyOrFriends}
                            </span>
                          )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="Data_HasDisabilityLivingAllowance">
                          Does the child / young person receive Disability
                          Living Allowance (DLA)? (required)
                        </label>
                        <select
                          className="form-control"
                          id="Data_HasDisabilityLivingAllowance"
                          name="StudentDetailsHasDisabilityLivingAllowance"
                          onChange={handleChange}
                        >
                          <option value="">Select value</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.StudentDetailsHasDisabilityLivingAllowance &&
                          !data.StudentDetailsHasDisabilityLivingAllowance && (
                            <span className="error">
                              {
                                errors.StudentDetailsHasDisabilityLivingAllowance
                              }
                            </span>
                          )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="Data_HasAppliedForBursary">
                          Has the child / young person applied to their chosen
                          school / college for a bursary? (required)
                        </label>
                        <select
                          className="form-control"
                          id="Data_HasAppliedForBursary"
                          name="EducationHasAppliedForBursary"
                          onChange={handleChange}
                        >
                          <option value="">Select value</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.EducationHasAppliedForBursary &&
                          !data.EducationHasAppliedForBursary && (
                            <span className="error">
                              {errors.EducationHasAppliedForBursary}
                            </span>
                          )}
                      </div>{" "}
                    </>
                  )}

                {data.EducationHasAppliedForBursary === "false" && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    id="noBursaryAlert"
                  >
                    If you or the child / young person haven't already applied
                    for a bursary from the chosen school/college please do so
                    before submitting your application for travel assistance.
                  </div>
                )}

                {(data.EducationSchoolType === EducationTypes.PRIMARY ||
                  data.EducationSchoolType === EducationTypes.SECONDARY)
                  && (data.GroundsForApplication && data.GroundsForApplication === GroundsForApplication.LOW_INCOME) && (
                    <div className="" id="detailLowIncome">
                      {/* Section: Low income application */}
                      <div className="mb-3">
                        <div className="alert alert-info" role="alert">
                          To apply on the grounds of low income you must provide
                          supporting evidence. Please select the relevant type
                          of evidence and upload your supporting documents after
                          submitting this form.
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="mb-1">
                          <label htmlFor="Data_FreeSchoolMealsEntitlement">
                            Proof of Free School Meals (FSM) entitlement
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            id="FsmYes"
                            type="radio"
                            value={true}
                            className="form-check-input"
                            name="FreeSchoolMealsEntitlement"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            id="FsmNo"
                            type="radio"
                            value={false}
                            className="form-check-input"
                            name="FreeSchoolMealsEntitlement"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        {errors.FreeSchoolMealsEntitlement &&
                          !data.FreeSchoolMealsEntitlement && (
                            <span className="error">
                              {errors.FreeSchoolMealsEntitlement}
                            </span>
                          )}
                      </div>

                      <div className="mb-3">
                        <div className="mb-1">
                          <label htmlFor="Data_MaximumTaxCredits">
                            Maximum level of working tax credits
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            id="tax_credits_yes"
                            type="radio"
                            value={true}
                            className="form-check-input"
                            name="MaximumTaxCredits"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            id="tax_credits_no"
                            type="radio"
                            value={false}
                            className="form-check-input"
                            name="MaximumTaxCredits"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        {errors.MaximumTaxCredits &&
                          !data.MaximumTaxCredits && (
                            <span className="error">
                              {errors.MaximumTaxCredits}
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                <div className="mb-3" id="ehcp">
                  <label htmlFor="Data_HasEhcpPlan">
                    Does the child / young person have a current Education,
                    Health and Care Plan (EHCP)? (required)
                  </label>
                  <select
                    className="form-control"
                    id="hasEhcpSelect"
                    name="EducationHasEHCPPlan"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.EducationHasEHCPPlan &&
                    !data.EducationHasEHCPPlan && (
                      <span className="error">
                        {errors.EducationHasEHCPPlan}
                      </span>
                    )}
                </div>

                {data.EducationHasEHCPPlan === "true" && (
                  <div className="mb-3" id="ehcpFinalisedDate">
                    <label htmlFor="Data_EhcpFinalisedDate">
                      Please tell us the date the EHCP was finalised (this can
                      be found on the front cover)
                    </label>
                    <input
                      className="form-control datepicker"
                      type="date"
                      id="Data_EhcpFinalisedDate"
                      name="EducationEHCPFinalisedDate"
                      placeholder="DD/MM/YYYY"
                      onChange={handleChange}
                    />
                    {errors.EducationEHCPFinalisedDate &&
                      !data.EducationEHCPFinalisedDate && (
                        <span className="error">
                          {errors.EducationEHCPFinalisedDate}
                        </span>
                      )}
                  </div>
                )}
                <div className="mb-3" id="hasOwnVehicle">
                  <label htmlFor="Data_HasOwnVehicle">
                    Do you own your own vehicle? (required)
                  </label>
                  <select
                    className="form-control"
                    id="Data_HasOwnVehicle"
                    name="TransportHasOwnVehicle"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.TransportHasOwnVehicle &&
                    !data.TransportHasOwnVehicle && (
                      <span className="error">
                        {errors.TransportHasOwnVehicle}
                      </span>
                    )}
                </div>

                <div className="mb-3">
                  <label htmlFor="Data_TransportInYear/NextAcademicYear">
                    Are you applying for travel assistance for the next academic year or within the school year? (required)
                  </label>
                  <select
                    id="Data_TransportInYear/NextAcademicYear"
                    className="form-control"
                    name="InYearOrNextAcademicYear"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={126560000}>In-Year</option>
                    <option value={126560001}>Next Academic Year</option>
                  </select>
                  {errors.InYearOrNextAcademicYear && !data.InYearOrNextAcademicYear && (
                    <span className="error">
                      {errors.InYearOrNextAcademicYear}
                    </span>
                  )}
                </div>

                <div className="mb-3" id="TransportStartDate">
                  <label htmlFor="Data_TransportStartDate">
                    What date would you like travel assistance to start?
                    DD/MM/YYYY (required)
                  </label>
                  <input
                    className="form-control datepicker"
                    type="date"
                    id="Data_TransportStartDate"
                    name="TransportStartDate"
                    placeholder="DD/MM/YYYY"
                    onChange={handleChange}
                  />
                  <div id="helpBlock" className="form-text">
                    This date is for information only. Please note that we
                    cannot confirm a start date until after your application has
                    been agreed.
                  </div>
                  {errors.TransportStartDate && !data.TransportStartDate && (
                    <span className="error">{errors.TransportStartDate}</span>
                  )}
                </div>

                <div className="mb-3" id="OtherDetails">
                  <label htmlFor="Data_OtherDetails">
                    Please provide any other details that you feel will help us
                    to make a decision on whether we can offer travel assistance
                    to the child / young person (optional)
                  </label>
                  <div className="mb-3">
                    <textarea
                      rows={3}
                      maxLength={3999}
                      autoComplete="off"
                      className="form-control"
                      id="Data_OtherDetails"
                      name="OtherDetails"
                      placeholder="Specify additional details"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div id="emergencyContact" className="card mb-3">
                <div className="">
                  <div className="alert alert-warning" role="alert">
                    Please provide details for two emergency contacts, other
                    than anyone whose details have already been provided on this
                    form, they must be 18 years or older.
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact1Name">
                          Please supply full name of emergency contact 1
                          (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="text"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact1Name"
                            name="EmergencyContact1Name"
                            placeholder="Specify full name"
                            onChange={handleChange}
                            value={data.EmergencyContact1Name}
                          />
                          {errors.EmergencyContact1Name &&
                            !data.EmergencyContact1Name && (
                              <span className="error">
                                {errors.EmergencyContact1Name}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact1Relationship">
                          What is the relationship of emergency contact 1 to the
                          child / young person? (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="text"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact1Relationship"
                            name="EmergencyContact1Relationship"
                            placeholder="Specify relationship"
                            onChange={handleChange}
                            value={data.EmergencyContact1Relationship}
                          />
                          {errors.EmergencyContact1Relationship &&
                            !data.EmergencyContact1Relationship && (
                              <span className="error">
                                {errors.EmergencyContact1Relationship}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact1Phone">
                          Contact number for emergency contact 1 (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="tel"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact1Phone"
                            name="EmergencyContact1Phone"
                            placeholder="Specify contact number"
                            onChange={handleChange}
                            value={data.EmergencyContact1Phone}
                          />
                          {errors.EmergencyContact1Phone &&
                            !data.EmergencyContact1Phone.match(phoneRegExp) && (
                              <span className="error">
                                {errors.EmergencyContact1Phone}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact1Email">
                          Email address for emergency contact 1{" "}
                        </label>
                        <div className="mb-3">
                          <input
                            type="email"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact1Email"
                            name="EmergencyContact1Email"
                            placeholder="Specify email address"
                            onChange={handleChange}
                            value={data.EmergencyContact1Email}
                          />
                          {errors.EmergencyContact1Email &&
                            !data.EmergencyContact1Email.match(emailRegExp) && (
                              <span className="error">
                                {errors.EmergencyContact1Email}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact2Name">
                          Please supply full name of emergency contact 2
                          (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="text"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact2Name"
                            name="EmergencyContact2Name"
                            placeholder="Specify full name"
                            onChange={handleChange}
                            value={data.EmergencyContact2Name}
                          />
                          {errors.EmergencyContact2Name &&
                            !data.EmergencyContact2Name && (
                              <span className="error">
                                {errors.EmergencyContact2Name}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact2Relationship">
                          What is the relationship of emergency contact 2 to the
                          child / young person? (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="text"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact2Relationship"
                            name="EmergencyContact2Relationship"
                            placeholder="Specify relationship"
                            onChange={handleChange}
                            value={data.EmergencyContact2Relationship}
                          />
                          {errors.EmergencyContact2Relationship &&
                            !data.EmergencyContact2Relationship && (
                              <span className="error">
                                {errors.EmergencyContact2Relationship}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact2Phone">
                          Contact number for emergency contact 2 (required)
                        </label>
                        <div className="mb-3">
                          <input
                            type="tel"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact2Phone"
                            name="EmergencyContact2Phone"
                            placeholder="Specify contact number"
                            onChange={handleChange}
                            value={data.EmergencyContact2Phone}
                          />
                          {errors.EmergencyContact2Phone &&
                            !data.EmergencyContact2Phone.match(phoneRegExp) && (
                              <span className="error">
                                {errors.EmergencyContact2Phone}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="Data_EmergencyContact2Email">
                          Email address for emergency contact 2
                        </label>
                        <div className="mb-3">
                          <input
                            type="email"
                            maxLength={99}
                            autoComplete="off"
                            className="form-control"
                            id="Data_EmergencyContact2Email"
                            name="EmergencyContact2Email"
                            placeholder="Specify email address"
                            onChange={handleChange}
                            value={data.EmergencyContact2Email}
                          />
                          {errors.EmergencyContact2Email &&
                            !data.EmergencyContact2Email.match(emailRegExp) && (
                              <span className="error">
                                {errors.EmergencyContact2Email}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="alert alert-info" role="alert">
                The following information will not be used in your application
                for child / young person travel assistance but will be used to
                determine the correct support is provided if your application is
                successful.
              </div>

              <div className="card">
                <div className="mb-3">
                  <label htmlFor="Data_HasSendOrMedicalNeeds">
                    Does the child / young person have any special educational
                    needs or medical needs that we need to be aware of when
                    travelling? (required)
                  </label>
                  <select
                    id="sendMedicalSelect"
                    className="form-control"
                    name="SendOrMedicalHasSendOrMedicalNeeds"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.SendOrMedicalHasSendOrMedicalNeeds &&
                    !data.SendOrMedicalHasSendOrMedicalNeeds && (
                      <span className="error">
                        {errors.SendOrMedicalHasSendOrMedicalNeeds}
                      </span>
                    )}
                </div>

                {data.SendOrMedicalHasSendOrMedicalNeeds === "true" && (
                  <div id="hasSendOrMedicalNeeds" className="">
                    <div className="mb-3">
                      <label htmlFor="Data_SendOrMedicalNeeds">
                        Please describe the special educational needs or medical
                        needs of the child / young person (required)
                      </label>
                      <textarea
                        rows={3}
                        maxLength={3999}
                        autoComplete="off"
                        className="form-control"
                        id="Data_SendOrMedicalNeeds"
                        name="SendOrMedicalDetails"
                        placeholder="Specify special educational or medical needs"
                        onChange={handleChange}
                      ></textarea>
                      <div className="form-text">
                        Examples of this may be; communication, visual, hearing,
                        learning or behaviour. If the child / young person has a
                        diagnosis e.g. Autism or ADHD then please include this.
                      </div>
                      {errors.SendOrMedicalDetails &&
                        !data.SendOrMedicalDetails && (
                          <span className="error">
                            {errors.SendOrMedicalDetails}
                          </span>
                        )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="Data_HasMobilityIssues">
                        Does the child / young person have mobility issues?
                        (required)
                      </label>
                      <select
                        id="hadMobilityIssuesSelect"
                        className="form-control"
                        name="MobilityHasIssues"
                        onChange={handleChange}
                      >
                        <option value="">Select value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                      {errors.MobilityHasIssues && !data.MobilityHasIssues && (
                        <span className="error">
                          {errors.MobilityHasIssues}
                        </span>
                      )}
                    </div>

                    {data.MobilityHasIssues === "true" && (
                      <div className="" id="mobilityIssues">
                        <div className="mb-3">
                          <label htmlFor="Data_MobilityIssues">
                            Please state the mobility issues that that child /
                            young person has (required)
                          </label>
                          <textarea
                            rows={3}
                            maxLength={3999}
                            autoComplete="off"
                            className="form-control"
                            id="Data_MobilityIssues"
                            name="MobilityDetails"
                            placeholder="Specify mobility issues"
                            onChange={handleChange}
                          ></textarea>
                          {errors.MobilityDetails && !data.MobilityDetails && (
                            <span className="error">
                              {errors.MobilityDetails}
                            </span>
                          )}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="Data_MobilityEquipment">
                            Does the child / young person use any of the
                            following equipment?
                          </label>
                          <select
                            id="mobilityEquipmentSelect"
                            className="form-control"
                            name="MobilityEquipment"
                            onChange={handleChange}
                          >
                            <option value={-1}>Select value</option>
                            {mobilityEquipmentTypes &&
                              mobilityEquipmentTypes.map((equipment) => (
                                <option value={equipment.attributeValue}>
                                  {equipment.attributeName}
                                </option>
                              ))}
                            {errors.MobilityEquipment &&
                              !data.MobilityEquipment && (
                                <span className="error">
                                  {errors.MobilityEquipment}
                                </span>
                              )}
                          </select>
                        </div>

                        {data.MobilityEquipment &&
                          data.MobilityEquipment !== -1 &&
                          data.MobilityEquipment !==
                          MobilityEquipmentTypes.NOT_APPLICABLE &&
                          data.MobilityEquipment !==
                          MobilityEquipmentTypes.WALKING_FRAME && (
                            <div>
                              <div className="mb-3">
                                <label htmlFor="Data_MobilityEquipmentDimensions">
                                  Please supply the height and length of the
                                  wheelchair or buggy (required)
                                </label>
                                <textarea
                                  rows={3}
                                  maxLength={3999}
                                  className="form-control"
                                  id="Data_MobilityEquipmentDimensions"
                                  name="MobilityEquipmentDimensions"
                                  placeholder="Specify height and length of equipment"
                                  onChange={handleChange}
                                ></textarea>
                                {errors.MobilityEquipmentDimensions &&
                                  !data.MobilityEquipmentDimensions && (
                                    <span className="error">
                                      {errors.MobilityEquipmentDimensions}
                                    </span>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label htmlFor="Data_CanTransferToSeatWhilstTravelling">
                                  Can the child / young person transfer to a
                                  seat whilst travelling? (required)
                                </label>
                                <select
                                  className="form-control"
                                  id="Data_CanTransferToSeatWhilstTravelling"
                                  name="MobilityCanTransferToSeatWhilstTravelling"
                                  onChange={handleChange}
                                >
                                  <option value="">Select value</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                                {errors.MobilityCanTransferToSeatWhilstTravelling &&
                                  !data.MobilityCanTransferToSeatWhilstTravelling && (
                                    <span className="error">
                                      {
                                        errors.MobilityCanTransferToSeatWhilstTravelling
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}

                    <div className="mb-3">
                      <label htmlFor="Data_HasSeizures">
                        Does the child / young person suffer from seizures?
                        (required)
                      </label>
                      <select
                        className="form-control"
                        id="hasSeizuresSelect"
                        name="SeizuresHasSeizures"
                        onChange={handleChange}
                      >
                        <option value="">Select value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                      {errors.SeizuresHasSeizures &&
                        !data.SeizuresHasSeizures && (
                          <span className="error">
                            {errors.SeizuresHasSeizures}
                          </span>
                        )}
                    </div>

                    {data.SeizuresHasSeizures === "true" && (
                      <div className="" id="seizureDetails">
                        <div className="alert alert-warning" role="alert">
                          In the event of a seizure our drivers are expected to
                          stop and call emergency services. They are not
                          permitted to administer medication.
                        </div>
                        <div className="mb-3">
                          <label htmlFor="Data_SeizureType">
                            Please state the type of seizure that the child /
                            young person suffers from (required)
                          </label>
                          <textarea
                            rows={3}
                            maxLength={3999}
                            autoComplete="off"
                            className="form-control"
                            id="Data_SeizureType"
                            name="SeizuresType"
                            placeholder="Specify seizure type"
                            onChange={handleChange}
                          ></textarea>
                          {errors.SeizuresType && !data.SeizuresType && (
                            <span className="error">{errors.SeizuresType}</span>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="Data_SeizureSigns">
                            Please state any signs that the child / young person
                            may show before a seizure occurs
                          </label>
                          <textarea
                            rows={3}
                            maxLength={3999}
                            className="form-control"
                            id="Data_SeizureSigns"
                            name="SeizuresSigns"
                            placeholder="Specify seizure signs"
                            onChange={handleChange}
                          ></textarea>
                          {errors.SeizuresSigns && !data.SeizuresSigns && (
                            <span className="error">
                              {errors.SeizuresSigns}
                            </span>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="Data_SeizureFrequency">
                            How often do the seizures occur? (required)
                          </label>
                          <input
                            maxLength={99}
                            className="form-control"
                            autoComplete="off"
                            type="text"
                            id="SeizuresFrequency"
                            name="SeizuresFrequency"
                            placeholder="Specify how often the seizures occur"
                            onChange={handleChange}
                          />
                          {errors.SeizuresFrequency &&
                            !data.SeizuresFrequency && (
                              <span className="error">
                                {errors.SeizuresFrequency}
                              </span>
                            )}
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <label htmlFor="Data_HasTransportMedication">
                        Does any medication need to be transported with the
                        child / young person? (required)
                      </label>
                      <select
                        id="hasTransportMedicationSelect"
                        className="form-control"
                        name="Transport_HasTransportMedication"
                        onChange={handleChange}
                      >
                        <option value="">Select value</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                      {errors.Transport_HasTransportMedication &&
                        !data.Transport_HasTransportMedication && (
                          <span className="error">
                            {errors.Transport_HasTransportMedication}
                          </span>
                        )}
                    </div>

                    {data.Transport_HasTransportMedication === "true" && (
                      <div className="mb-3" id="medicationDetails">
                        <label htmlFor="Data_MedicationDetails">
                          Please specify the type of medication and any special
                          considerations to take in the transportation and
                          storage of the medication (required)
                        </label>
                        <textarea
                          rows={3}
                          maxLength={3999}
                          autoComplete="off"
                          className="form-control"
                          id="Data_MedicationDetails"
                          name="Transport_MedicationDetails"
                          placeholder="Specify medication details"
                          onChange={handleChange}
                        ></textarea>
                        {errors.Transport_MedicationDetails &&
                          !data.Transport_MedicationDetails && (
                            <span className="error">
                              {errors.Transport_MedicationDetails}
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                )}

                {/* <div className="mb-3">
                  <label htmlFor="Data_TransportSeatType">
                    Does the child / young person require any of the following?
                    (required)
                  </label>
                  <select
                    id="transportSeatTypeSelect"
                    className="form-control"
                    name="TransportSeatType"
                    onChange={handleChange}
                  >
                    <option value={-1}>Select value</option>
                    {seatTypes &&
                      seatTypes.map((seat) => (
                        <option value={seat.attributeValue}>
                          {seat.attributeName}
                        </option>
                      ))}
                  </select>
                  {errors.TransportSeatType && !data.TransportSeatType && (
                    <span className="error">{errors.TransportSeatType}</span>
                  )}
                </div> */}

                {/* {data.TransportSeatType &&
                  data.TransportSeatType !== -1 &&
                  data.TransportSeatType !== Seat.NOT_APPLICABLE && (
                    <div className="" id="seatDetail">
                      <div className="mb-3">
                        <label htmlFor="Data_HarnessRequired">
                          Does the child / young person require a special
                          harness or seatbelt? (required)
                        </label>
                        <select
                          className="form-control"
                          id="Data_HarnessRequired"
                          name="TransportHarnessRequired"
                          onChange={handleChange}
                        >
                          <option value="">Select value</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {errors.TransportHarnessRequired &&
                          !data.TransportHarnessRequired && (
                            <span className="error">
                              {errors.TransportHarnessRequired}
                            </span>
                          )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="Data_SeatDetails">
                          Please specify the type of seat including height and
                          weight of the seat, seat belt or enhancements needed
                          (required)
                        </label>
                        <textarea
                          rows={3}
                          maxLength={3999}
                          autoComplete="off"
                          className="form-control"
                          id="Data_SeatDetails"
                          name="TransportSeatDetails"
                          placeholder="Specify seat details"
                          onChange={handleChange}
                        ></textarea>
                        {errors.TransportSeatDetails &&
                          !data.TransportSeatDetails && (
                            <span className="error">
                              {errors.TransportSeatDetails}
                            </span>
                          )}
                      </div>
                    </div>
                  )} */}

                {/* <div className="mb-3">
                  <label htmlFor="Data_RemoveSeatBelt">
                    Will the child / young person try to take off their
                    seatbelt? (required)
                  </label>
                  <select
                    className="form-control"
                    id="Data_RemoveSeatBelt"
                    name="TransportRemoveSeatBelt"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.TransportRemoveSeatBelt &&
                    !data.TransportRemoveSeatBelt && (
                      <span className="error">
                        {errors.TransportRemoveSeatBelt}
                      </span>
                    )}
                </div> */}

                <div className="mb-3">
                  <label htmlFor="Data_RoadSafety">
                    Does the child / young person have a basic understanding of
                    road safety? (required)
                  </label>
                  <select
                    className="form-control"
                    id="Data_RoadSafety"
                    name="TransportRoadSafety"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.TransportRoadSafety && !data.TransportRoadSafety && (
                    <span className="error">{errors.TransportRoadSafety}</span>
                  )}
                </div>

                {/* <div className="mb-3">
                  <label htmlFor="Data_ShareTransport">
                    Is the child / young person able to share transport with
                    other passengers? (required)
                  </label>
                  <select
                    id="TransportShareTransport"
                    className="form-control"
                    name="TransportShareTransport"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.TransportShareTransport &&
                    !data.TransportShareTransport && (
                      <span className="error">
                        {errors.TransportShareTransport}
                      </span>
                    )}
                </div> */}

                {/* {data.TransportShareTransport === "false" && (
                  <div className="mb-3">
                    <label htmlFor="Data_transportNoSharedTransportDetails">
                      Please state why the child / young person can't share
                      transport (required)
                    </label>
                    <textarea
                      rows={3}
                      maxLength={3999}
                      autoComplete="off"
                      className="form-control"
                      id="Data_transportNoSharedTransportDetails"
                      name="TransportNoSharedTransportDetails"
                      placeholder="Specify why child / young person cannot share transport"
                      onChange={handleChange}
                    ></textarea>
                    {errors.TransportNoSharedTransportDetails &&
                      !data.TransportNoSharedTransportDetails && (
                        <span className="error">
                          {errors.TransportNoSharedTransportDetails}
                        </span>
                      )}
                  </div>
                )} */}

                {/* <div className="mb-3">
                  <label htmlFor="Data_transportStudentJourneyDetails">
                    Please give any other details that you feel would help us to
                    make the journey easier for the child / young person
                  </label>
                  <textarea
                    rows={3}
                    maxLength={3999}
                    autoComplete="off"
                    className="form-control"
                    id="Data_transportStudentJourneyDetails"
                    name="TransportStudentJourneyDetails"
                    placeholder="Specify other details"
                    onChange={handleChange}
                  ></textarea>
                  <div className="form-text">
                    For example, any likes or dislikes
                  </div>
                </div> */}

                {/* <div className="mb-3">
                  <label htmlFor="Data_SupportCalming">
                    What helps calm the child / young person if they become
                    upset during transport?
                  </label>
                  <textarea
                    rows={3}
                    maxLength={3999}
                    autoComplete="off"
                    className="form-control"
                    id="Data_SupportCalming"
                    name="SupportCalming"
                    placeholder="Specify calming details"
                    onChange={handleChange}
                  ></textarea>
                </div> */}

                <div className="mb-3">
                  <label htmlFor="Data_TravelTraining">
                    Has the child / young person ever taken part in travel
                    training that has been provided by the Council? (required)
                  </label>
                  <select
                    className="form-control"
                    id="Data_TravelTraining"
                    name="TransportTravelTraining"
                    onChange={handleChange}
                  >
                    <option value="">Select value</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.TransportTravelTraining &&
                    !data.TransportTravelTraining && (
                      <span className="error">
                        {errors.TransportTravelTraining}
                      </span>
                    )}
                </div>

                <div className="mb-3">
                  <label htmlFor="Data_SupportOther">
                    Please provide any other details that you feel will help us
                    to make a decision on the appropriate type of support
                  </label>
                  <textarea
                    rows={3}
                    maxLength={3999}
                    autoComplete="off"
                    className="form-control"
                    id="Data_SupportOther"
                    name="SupportOther"
                    placeholder="Specify any other details"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="alert alert-info" role="alert">
                Please continue to step 2 where you will be able to upload any
                supporting documentation before submitting your application.
              </div>
            </div>
            <hr />
            <button
              className="index-form-btn"
              onClick={() => handleClick("step2")}
            >
              <i
                className="fa fa-arrow-circle-right"
                title="Continue to step 2"
              >
                &nbsp;
              </i>
              Continue to step 2
            </button>
          </div>
        </>
      )}
      {step === "step2" && <Uploader props={data} />}
    </Container>
  );
};

export default Form;
