import React, { useEffect, useState } from "react";
import { createTransportRequest } from "../../services/Index";
import {
  checkboxesListSchema,
  formatTransportRequestData,
} from "../../utils/Helper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RotatingLines } from "react-loader-spinner";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Confirm = (props) => {
  let navigate = useNavigate();
  const [transportRequestDetails, setTransportRequestDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const [checkboxes, setCheckboxes] = useState({
    ReadTransportPolicy: false,
    UploadedEvidence: false,
    ReadTransportPost16Policy: false,
    AccurateDetails: false,
  })

  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trd = JSON.parse(JSON.stringify(formatTransportRequestData(props.props)));

    setTransportRequestDetails(trd)
  }, []);

  const handleChange = (e) => {
    setCheckboxes((oldData) => ({
      ...oldData,
      [e.target.name]: !checkboxes[e.target.name],
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const isValid = await checkboxesListSchema.isValid(checkboxes);

    if (isValid) {
      try {
        setLoading(true);
        await createTransportRequest(transportRequestDetails);
        setLoading(false);
        navigate("/Applications");
        toast.success("Transport request submitted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.log(error);
      }
      setError(false);
    } else {
      setError(true);
      toast.error("Please select all checkboxes to submit the travel assistance application.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="riskassessment-footer">
      <Container>
        <div id="contentDoc">
          <div id="content">
            <div class="content-wrap confirmation-form">
              {loading && (
                <div className="center">
                  <RotatingLines
                    strokeColor="#4e3782"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                  />
                </div>
              )}
              {!loading && (
                <div id="byEditor" class="byEditor article">
                  <div class="formhead">
                    <i class="fa fa-upload">&nbsp;</i>
                    <span>Application for Travel Assistance</span>
                  </div>
                  <h2>Step 3 - Confirmation</h2>
                  <hr />
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <h3>I certify that:</h3>
                      <hr />
                      <form autocomplete="false">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="ReadTransportPolicy"
                            onClick={handleChange}
                          />
                          <label
                            class="form-check-label"
                            for="Data_ReadTransportPolicy"
                          >
                            I have read the{" "}
                            <a
                              href="https://www.telford.gov.uk/info/20466/travelling_to_school/10/home_to_school_transport/2"
                              target="_blank"
                            >
                              travel assistance policy (opens in a new window)
                            </a>{" "}
                            in respect of travel assistance.
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="AccurateDetails"
                            onClick={handleChange}
                          />
                          <label
                            class="form-check-label"
                            for="Data_AccurateDetails"
                          >
                            The details I have provided are true and accurate to
                            the best of my knowledge.
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="UploadedEvidence"
                            onClick={handleChange}
                          />
                          <label
                            class="form-check-label"
                            for="Data_UploadedEvidence"
                          >
                            I have uploaded any evidence required to support
                            this application.
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="ReadTransportPost16Policy"
                            onClick={handleChange}
                          />
                          <label
                            class="form-check-label"
                            for="Data_UploadedEvidence"
                          >
                            Please be aware that in line with the Post 16 travel
                            assistance policy you will be requested to pay a
                            contribution of £600 per annum. The charge will be
                            £400 per academic year, if a financially dependent
                            child / young person, is from a low income
                            household.
                          </label>
                        </div>
                        <hr />
                        <button
                          class="index-form-btn"
                          onClick={async (e) => await handleClick(e)}
                        >
                          <i class="fa fa-paper-plane">&nbsp;</i>Submit
                          application
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Confirm;
