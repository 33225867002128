import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getCustomerDetails, updateCustomerDetails } from "../services/Index";
import { contactTitles } from "../utils/GlobalChoices";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { profileSettingsSchema } from "../utils/Helper";

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCustomerDetails({}).then((response) => {
      // response.email = AzureADB2C.email;
      localStorage.setItem("customerDetails", JSON.stringify(response));
      if (response.dateofBirth) {
        response.dateofBirth = getFormattedDate(response.dateofBirth);
        if (response.dateofBirth == "1-01-01") {
          response.dateofBirth = null;
        }
      }
      if (response.title == -1) {
        response.title = null;
      }
      setProfile(response);
      setCustomerDetails(response);
    });
  }, []);

  const getFormattedDate = (dateofBirth) => {
    const dateString = dateofBirth;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // add leading zero if needed
    const day = ("0" + date.getDate()).slice(-2); // add leading zero if needed
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };
  const handleChange = (e) => {
    e.preventDefault();
    setDataChanged(true);
    const { name, value } = e.target;

    setProfile((oldData) => ({ ...oldData, [name]: value }));
    setIsButtonDisabled(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidated = await validateTransportRequestSchemma(profile);

    if (isValidated) {
      if (dataChanged === false) {
        toast.warning("There is nothing to be updated yet.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
        setIsButtonDisabled(true);
        return;
      }
      try {
        const response = await updateCustomerDetails(profile);
        localStorage.setItem("customerDetails", JSON.stringify(response));
        toast.success("Profile has been updated successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.log(error);
      }
      setDataChanged(false);
    }
  };
  const validateTransportRequestSchemma = async (data) => {
    // return true;
    const isValid = await profileSettingsSchema.isValid(data);
    if (isValid === true) {
      return true;
    } else {
      try {
        await profileSettingsSchema.validate(data, { abortEarly: false });
        setErrors({});
      } catch (err) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        toast.error("Please fill all the required fields.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });
        console.log("These are errors: ", validationErrors);
      }
      return false;
    }
  };
  return (
    <div className="settings-main">
      <div className="container">
        <div className="formhead">
          <i className="fa fa-clipboard">&nbsp;</i>
          <span className="text-left">Profile Settings</span>
        </div>
        <div>
          <h2>What do I need to do if my address is changing? </h2>
          <p>
            <div class="alert alert-warning" role="alert">
              <i class="fa fa-info-circle">&nbsp;</i>
              A new application form will need to be filled in. If you know you are moving in advance, please let us know as soon as possible.
            </div>
          </p>
        </div>
        <Form>
          <h2 className="mt-3">Basic Information</h2>
          <div className="row setting-box">
            <div className="col-md-4">
              <label>Title</label>
              <Form.Select name="title" onChange={handleChange} disabled={customerDetails.title}>
                <option>Select Title</option>
                {contactTitles &&
                  contactTitles.map((title) => (
                    <option value={title.attributeValue} selected={title.attributeValue === profile.title}>
                      {title.attributeName}
                    </option>
                  ))}
              </Form.Select>
              {errors.title && !profile.title && (
                <span className="error">{errors.title}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>First Name</label>
              <Form.Control type="text" name="firstName" placeholder="First Name" value={profile.firstName} onChange={handleChange} disabled={customerDetails.firstName} />
              {errors.firstName && !profile.firstName && (
                <span className="error">{errors.firstName}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Last Name</label>
              <Form.Control type="text" name="lastName" placeholder="Last Name" value={profile.lastName} onChange={handleChange} disabled={customerDetails.lastName} />
              {errors.lastName && !profile.lastName && (
                <span className="error">{errors.lastName}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Date of Birth </label>
              <Form.Control type="date" name="dateofBirth" placeholder="Date of Birth " value={profile.dateofBirth} onChange={handleChange} disabled={customerDetails.dateofBirth} />
              {errors.dateofBirth && !profile.dateofBirth && (
                <span className="error">{errors.dateofBirth}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Job Title </label>
              <Form.Control type="text" name="jobTitle" placeholder="Job Title " value={profile.jobTitle} onChange={handleChange} />
              {errors.jobTitle && !profile.jobTitle && (
                <span className="error">{errors.jobTitle}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Email </label>
              <Form.Control type="email" name="email" placeholder="Email" disabled value={profile.email} />
            </div>
            <div className="col-md-4">
              <label>Phone No.</label>
              <Form.Control type="tel" name="phone" placeholder="Phone No." value={profile.phone} onChange={handleChange} />
              {errors.phone && !profile.phone && (
                <span className="error">{errors.phone}</span>
              )}
            </div>
          </div>
          <h2 className="mt-3">Address </h2>
          <div className="row setting-box">
            <div className="col-md-4">
              <label>House name / number </label>
              <Form.Control type="text" name="addressline1" placeholder="House name/number " value={profile.addressline1} onChange={handleChange} disabled={customerDetails.addressline1} />
              {errors.addressline1 && !profile.addressline1 && (
                <span className="error">{errors.addressline1}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Street name</label>
              <Form.Control type="text" name="addressline2" placeholder="Street name" value={profile.addressline2} onChange={handleChange} disabled={customerDetails.addressline2} />
              {errors.addressline2 && !profile.addressline2 && (
                <span className="error">{errors.addressline2}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Area</label>
              <Form.Control type="text" name="addressline3" placeholder="Area" value={profile.addressline3} onChange={handleChange} disabled={customerDetails.addressline3} />
              {errors.addressline3 && !profile.addressline3 && (
                <span className="error">{errors.addressline3}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Town/city </label>
              <Form.Control type="text" name="city" placeholder="Town/city " value={profile.city} onChange={handleChange} disabled={customerDetails.city} />
              {errors.city && !profile.city && (
                <span className="error">{errors.city}</span>
              )}
            </div>
            <div className="col-md-4">
              <label>Post Code </label>
              <Form.Control type="text" className="text-uppercase" name="postalCode" placeholder="Post Code " value={profile.postalCode} onChange={handleChange} disabled={customerDetails.postalCode} />
              {errors.postalCode && !profile.postalCode && (
                <span className="error">{errors.postalCode}</span>
              )}
            </div>
          </div>

          {/* <h2 className="mt-3">Banking Details </h2>
          <div className="row setting-box">
            <div className="col-md-4">
              <label>Account Name  </label>
              <Form.Control type="text" name="accountName" placeholder="Account Name  " value={profile.accountName} onChange={handleChange} />
            </div>
            <div className="col-md-4">
              <label>Sort Code </label>
              <Form.Control type="text" name="sortCode" placeholder="Sort Code " value={profile.sortCode} onChange={handleChange} />
            </div>
            <div className="col-md-4">
              <label>Account Number</label>
              <Form.Control type="text" name="accountNumber" placeholder="Account Number" value={profile.accountNumber} onChange={handleChange} />
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              <button
                onClick={(e) => handleSubmit(e)}
                disabled={isButtonDisabled}
                style={isButtonDisabled ? { backgroundColor: 'gray', color: 'white' } : {}}
              >
                Update
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
