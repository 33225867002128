import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { getTransportAgreements } from "../../services/Index";

const TransportAgreementList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [transportAgreements, setTransportAgreements] = useState([]);
  // const history = useHistory();

  useEffect(() => {
    getTransportAgreements().then((res) => {
      setTransportAgreements(res);
      setLoading(false);
    });
  }, []);

  // const handleNavigate = (agreement) => {
  //   if (agreement === "PTB") {
  //     navigate("/TransportAgreementPTB");
  //   } else if (agreement === "Bike") {
  //     navigate("/TransportAgreementHSB");
  //   }

  // };

  return (
    <div className="riskassessment-footer">
      <div className="container">
        <div className="list-table">
          <h1 className="mt-3"> List of Transport Agreements</h1>
          {loading && (
            <div className="center">
              <RotatingLines
                strokeColor="#4e3782"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}
          {transportAgreements.length > 0 && (
            <div className="">
              <div className="mb-3 mt-4"></div>
              <div className="table-width-box">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Transport Request</th>
                      <th>Child/Young Person Name</th>
                      <th>Agreement Number</th>
                      <th>Passenger Number</th>
                      <th>Contract Type</th>
                      <th>Transport Agreement Status</th>
                      <th>Terms and Condition</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transportAgreements &&
                      transportAgreements.map((agreement) => (
                        <tr>
                          <td>
                            {agreement?.transportRequest &&
                              agreement.transportRequest.transportRequestNumber}
                          </td>
                          <td>
                            {agreement?.student && agreement?.student?.firstName + " "
                              + agreement?.student?.lastName}
                          </td>
                          <td>
                            {agreement?.agreementNumber}
                          </td>
                          <td>
                            {agreement?.passenger?.passengerNumber}
                          </td>
                          <td>
                            {agreement?.contract?.contractType
                              ? agreement?.contract?.contractType
                              : "N/A"}
                          </td>
                          <td>
                            {agreement?.statusReasonName &&
                              agreement?.statusReasonName}
                          </td>
                          <td>
                            {agreement?.termsandConditionsAccepted === true
                              ? "Accepted"
                              : "Pending"}
                          </td>
                          <td className="tr-table-btn">
                            <button>
                              <Link
                                to={agreement?.contract?.contractType === "PTB" ? '/TransportAgreementPTB' : (
                                  agreement?.contract?.contractType === "Bike" ? '/TransportAgreementHSB' : ""
                                )}
                                state={{ from: agreement }}
                              >
                                Open Agreement
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {transportAgreements.length === 0 && loading === false && (
          <div className="alert alert-info mt-3">
            You do not yet have any transport agreements to review.
          </div>
        )}
      </div>
    </div>
  );
};

export default TransportAgreementList;
